/**
 * A Safe component is an element with sanitized content.
 * It supports these props:
 *   type: element to render, default = div
 *   content: the text to be sanitized
 * Any others are rendered as attributes of the target element.
 */

import React from 'react';
import { Link } from 'gatsby';
import sanitizeHtml from 'sanitize-html';

export default function Safe(props) {
  const fixedProps = ['type', 'content'];
  const rootProps = Object.keys(props).filter(attrib => !fixedProps.includes(attrib)).reduce((root, attrib) => {
    root[attrib] = props[attrib];
    return root;
    }, {});
  // Setting option allowAttributes to false enables use of "class" attribute
  rootProps.dangerouslySetInnerHTML = {__html: sanitizeHtml(props.content, {allowedAttributes: false})};

  const elementType = (props.type ? props.type.toLowerCase() : null);
  if (elementType==='li') return <li {...rootProps}></li>
  else if (elementType==='p') return <p {...rootProps}></p>
  else if (elementType==='span') return <span {...rootProps}></span>
  else if (elementType==='option') return <option {...rootProps}></option>
  else if (elementType==='a') return <a {...rootProps}></a>
  else if (elementType==='h1') return <h1 {...rootProps}></h1>
  else if (elementType==='h2') return <h2 {...rootProps}></h2>
  else if (elementType==='h3') return <h3 {...rootProps}></h3>
  else if (elementType==='link') return <Link {...rootProps}></Link>
  else return <div {...rootProps}></div>
};

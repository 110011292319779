/**
 * Banner provides a container with a title, a button, and an image.
 * Supported props: title subtitle startstudy banner
 */
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { css } from '../util/pagetools';
import Safe from './Safe';

export default function Banner(props) {
  const cfg = props.src;

  return (
    <div id="banner" className={css(cfg.banner.theme)}>
      <div id="banner-image">
        <h1 className="banner-title">{cfg.title}</h1>
        <StaticImage src='../images/banner.jpg' alt="main banner" layout="fullWidth" />
      </div>
      <div className="banner-text">
        <Safe content={cfg.subtitle} />
      </div>
    </div>
  );
};

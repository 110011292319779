/**
 * Layout is a default page wrapper defining the major sections of the site.
 * Supported props: config, ctx, currentpath
 */

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Banner from './Banner';
import Nav from './NavToggle';
import PageSection from './PageSection';
import Footer from './Footer';
import CookieBanner from './CookieBanner';
import { setLinks } from '../util/pagetools';
import { localizer } from '../util/localizer';

export default function Layout(props) {
  const cfg = props.config;
  const localized = localizer(props.ctx);
  const toplinks = setLinks(cfg.sections);
  const footdata = {
    ...cfg.footer,
    toplinks,
    sitename: cfg.sitename,
    siteurl: cfg.siteurl
  };
  const {title, subtitle, startstudy, banner} = cfg;

  const pageSections = cfg.sections.map((section, index) => <PageSection key={section.id} config={section} />);
  const imgPanel = <div className="-fluid" key="imgPanel">
    <StaticImage src='../images/ctximg_1.jpg' alt="" className="mx-auto" />
  </div>;
  const imgPanel2 = <div id="bottom-img" className="-fluid" key="imgPanel2">
    <StaticImage src='../images/ctximg_2.jpg' alt="" className="mx-auto" />
  </div>;
  pageSections.splice(1, 0, imgPanel);
  pageSections.splice(4, 0, imgPanel2);

  return (
    <div id="page">
      <div id="layout-root">
        <header>
          <Nav toplinks={toplinks}
               sitename={cfg.sitename}
               theme={cfg.navbar.theme}
               ctx={props.ctx}
               currentpath={props.currentpath}
               localizerfn={localized} />
          <Banner src={{title, subtitle, startstudy, banner}} />
        </header>
        <main>
          {pageSections.slice(0, 2)}
          <div id="bbird">{pageSections.slice(2, 4)}</div>
          {pageSections.slice(4)}
        </main>
        {props.children}
        <Footer src={footdata} localizerfn={localized} />
      </div>
      <CookieBanner />
    </div>
  );
};

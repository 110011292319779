module.exports = {
  en: {
    path: 'en',
    default: true,
    variations: [
      {
        name: 'A',
        description: 'English Screener',
      }
    ]
  }
};

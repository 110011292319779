/**
 * This component renders most configuration objects for a page's sections.
 * Supported props: config localizerfn
 */
import React from 'react';
import Safe from './Safe';
import { css } from '../util/pagetools';

export default function PageSection(props) {
  const cfg = props.config;

  return (
    <div id={cfg.id} className={css('basic-section', cfg.theme)}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
            <h1 className={css('section-header', cfg.headerclass)}>
              <Safe type="span" content={cfg.header || cfg.title} />
            </h1>
            {renderParagraphs(cfg.text || cfg.data, cfg.theme, cfg.displayAs, props.localizerfn)}
          </div>
        </div>
      </div>
    </div>
  );
};

function renderParagraphs(parags, theme, paragraphType, localized) {
  if (!Array.isArray(parags)) return <p>?</p>;
  const collectionType = (typeof parags[0]);
  const styling = 'comp';
  // Plain strings render as simple paragraphs
  if (collectionType==='string') {
    if (paragraphType==='list') {
      return <ul>{parags.map((item, index) => <li key={index} className={styling}>{item}</li>)}</ul>;
    }
    else if (paragraphType==='faq') { return renderOrderedFaq(parags, styling); }
    else if (paragraphType==='ufaq') { return renderUnorderedFaq(parags, styling); }
    else if (paragraphType==='linker') { return renderEmbeddedLink(parags, styling, localized, '@'); }
    return parags.map((item, index) => <Safe key={index} className={styling} type="p" content={item} />);
  }
  // Objects may render as paragraphs or other structures, like lists
  return parags.reduce((output, paragraph, index) => {
    if (paragraph.items) {
      // Render a list
      output.push(<ul key={index}>{paragraph.items.map((item, idx) => <Safe key={`li_${idx}`} className={styling} type="li" content={item} />)}</ul>);
    } else {
      // Render a paragraph
      const pStyle = (paragraph.styling ? `${styling} ${paragraph.styling}` : styling);
      output.push(<Safe key={index} type="p" className={pStyle} content={paragraph.content} />);
    }
    return output;
    }, []);
}

function renderUnorderedFaq(parags, styling) {
  return parags.map((item, index) => {
    const [q, ans] = item.split('?');
    return (
      <div key={index}>
        <p className={css('faq', styling)}>{q+'?'}</p>
        {faqAnswer(ans, css('faq-ans', styling))}
      </div>
    );
  });
}

function renderOrderedFaq(parags, styling) {
  return (
    <ol>
      {parags.map((item, index) => {
        const [q, ans] = item.split('?');
        return (
          <li key={index}>
            <p className={`faq`}>{q+'?'}</p>
            {faqAnswer(ans, styling)}
          </li>
        );
      })}
    </ol>
  );
}

// Support multiple paragraphs by parsing by delimiter (semi-colon).
function faqAnswer(text, styling, useMarkup) {
  if (useMarkup) {
    if (text.indexOf(';')>=0) return text.split(';').map((parag,idx) => <Safe type="p" key={`faqp-${idx}`} className={styling} content={parag} />);
    return <Safe type="p" className={styling} content={text} />;
  }
  if (text.indexOf(';')>=0) return text.split(';').map((parag,idx) => <p key={`faqp-${idx}`} className={styling}>{parag}</p>);
  return <p className={styling}>{text}</p>;
}

function renderEmbeddedLink(parags, styling, localized, delim) {
  return parags.map((item, index) => {
    if (item.indexOf(delim)>=0) {
      const [before, label, after] = item.split(delim);
      return (
        <div key={index}>
          <Safe type="span" content={before} />
          <Safe type="Link" to={localized('/hcp')} content={label} />
          <Safe type="span" content={after} />
        </div>
      );
    }
    return <Safe key={index} className={styling} type="p" content={item} />;
  });
}
